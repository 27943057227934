import React, { Component } from "react";
import styled from "styled-components";

/*
const rand = num => Math.floor(Math.random() * (num - 1) + 0);

const fonts = [
  "Monoton",
  "Shrikhand",
  "Fjalla One",
  "Pacifico",
  "Lobster",
  "Acme",
  "Knewave",
  "Abril Fatface",
  "Permanent Marker",
  "Fredoka One",
  "Luckiest Guy",
  "Sigmar One"
];
*/

const StyledApp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
`;

const Logo = styled.h1`
  background-color: #e3241e;
  color: white;
  font-size: 3em;
  font-weight: 500;
  padding: 15px;
  letter-spacing: 0.12em;
  font-family: Pacifico, sans-serif;
  transform: perspective(409px) rotateY(10deg);
  transition: transform 0.1s;

  &:hover {
    transform: perspective(409px) rotateY(-15deg);
  }

  @media (max-width: 600px) {
    font-size: 1.5em;
  }
`;

const StyledSubheader = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  margin: 25px;
`;

const Contact = styled.a``;

class App extends Component {
  render() {
    return (
      <StyledApp>
        <Logo>nifty.supply</Logo>
        <StyledSubheader>Coming Soon!</StyledSubheader>
        <Contact href="mailto:hi@nifty.supply">Get in touch</Contact>
      </StyledApp>
    );
  }
}

export default App;
